import React from 'react';
import styled from 'styled-components';

import { black, white } from '../constants/colors';
import { tablet, laptop } from '../utils/breakpoints';

const Container = styled.header`
  width: 100vw;
  height: 100px;
  background-color: ${black};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: height 1s ease;

  ${tablet} {
    height: 120px;
  }
`;

const Logo = styled.img`
  max-width: 50px;
  padding: 0 0.5em;

  transition: max-width 1s ease;

  ${tablet} {
    max-width: 65px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-family: 'DirtyHeadline', sans-serif;
  text-transform: uppercase;
  color: ${white};
  padding: 0;
  margin: 0;
  font-size: 1.5rem;

  transition: font-size 1s ease;

  ${tablet} {
    font-size: 2rem;
  }

  ${laptop} {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h2`
  font-family: 'DirtyHeadline', sans-serif;
  text-transform: uppercase;
  color: ${white};
  padding: 0;
  margin: 0;
  font-size: 1rem;

  transition: font-size 1s ease;

  ${laptop} {
    font-size: 1.5rem;
  }
`;

export const Header = () => (
  <Container>
    <Logo src="logo512.png" alt="313 Care Collective Logo" />
    <TextContainer>
      <Title>313 Care Collective</Title>
      <Subtitle>Do No Harm. Take No Shit.</Subtitle>
    </TextContainer>
  </Container>
);

export default Header;
