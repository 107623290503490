import React from 'react';
import styled from 'styled-components';

import Section from './Section';
import PartnerLogo from './PartnerLogo';
import { tablet } from '../utils/breakpoints';

const PartnerList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  ${tablet} {
    flex-direction: row;
    flex: 1 0 32%;
  }
`;

export default function Partners() {
  return (
    <Section title={'Our Friends'} variant="light">
      <PartnerList>
        <PartnerLogo
          name="Detroit Will Breathe"
          href="//detroitwillbreathe.info/"
          src="partners/dwb.jpg"
        />
        <PartnerLogo
          name="Michigan Liberation"
          href="//miliberation.org/"
          src="partners/mi-lib.png"
        />
        <PartnerLogo
          name="Michigan National Lawyers Guild"
          href="//michigannlg.org/"
          src="partners/nlg.png"
        />
        <PartnerLogo name="SHIFT" href="//www.facebook.com/shiftmich" src="partners/shift.png" />
        <PartnerLogo
          name="SWARM"
          href="//www.facebook.com/SWARM-South-Warren-Alliance-of-Radical-Movement-104687941384981/"
          src="partners/swarm.png"
        />
        <PartnerLogo name="BAMN" href="//www.bamn.org/" src="partners/bamn.png" />
        <PartnerLogo
          name="Michigan Solidarity Bail Fund"
          href="//michigansolidaritybailfund.com/"
          src="partners/msbf.png"
        />
        <PartnerLogo
          name="Detroit Imagination Works"
          href="//www.detroitimaginationworks.org/"
          src="partners/diw.png"
        />
      </PartnerList>
    </Section>
  );
}
