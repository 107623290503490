import React from 'react';
import styled from 'styled-components';

import { laptop, tablet } from '../utils/breakpoints';
import { white, black } from '../constants/colors';

const Container = styled.section`
  background-color: ${({ variant = 'light' }) => (variant === 'light' ? white : black)};
  padding: 2em;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1rem;
    margin: 1em 0;
  }

  ${tablet} {
    p {
      font-size: 1.5rem;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  text-decoration: underline;
  color: ${({ variant = 'light' }) => (variant === 'light' ? black : white)};

  ${tablet} {
    font-size: 2rem;
  }
`;

const InnerContainer = styled.div`
  padding: 0.5em;

  color: ${({ variant = 'light' }) => (variant === 'light' ? black : white)};

  ${laptop} {
    margin: 0 auto;
    max-width: 66%;
  }
`;

export default function Section({ title, variant, children }) {
  return (
    <Container variant={variant}>
      <Title id={title} variant={variant}>
        {title}
      </Title>
      <InnerContainer variant={variant}>{children}</InnerContainer>
    </Container>
  );
}
