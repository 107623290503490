import React from 'react';
import styled from 'styled-components';

import { black, white } from '../constants/colors';

const Container = styled.header`
  width: 100vw;
  height: 75px;
  background-color: ${black};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${white};
`;

export const Header = () => {
  const year = new Date().getFullYear().toString();

  return (
    <Container>
      <TextContainer>&copy; {year} 313 Care Collective</TextContainer>
    </Container>
  );
};

export default Header;
