import React from 'react';

import track from '../utils/track';
import Header from './Header';
import Splash from './Splash';
import GetInvolved from './GetInvolved';
import Donate from './Donate';
import Partners from './Partners';
import Footer from './Footer';

function Home() {
  track('home-page');

  return (
    <div className="App">
      <Header />
      <Splash />
      <GetInvolved />
      <Donate />
      <Partners />
      <Footer />
    </div>
  );
}

export default Home;
