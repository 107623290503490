import React from 'react';
import styled from 'styled-components';

import Section from './Section';
import { black } from '../constants/colors';

const Link = styled.a`
  color: ${black};
  font-weight: bold;
`;

export default function GetInvolved() {
  return (
    <Section title={'Work With Us'} variant="light">
      <p>
        If you can help as a medic, trainer, or in another support role, or if you need street
        medics or logistical support for your event, please&nbsp;
        <Link href="mailto:313cc@protonmail.com" alt="313CC Medic Interest Email" target="_blank">
          reach out to us by email.
        </Link>
      </p>
    </Section>
  );
}
