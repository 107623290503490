import React from 'react';
import styled from 'styled-components';

import Section from './Section';
import { white } from '../constants/colors';
import { tablet, laptop, monitor } from '../utils/breakpoints';

const Email = styled.a`
  color: ${white};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${tablet} {
    flex-direction: row;
  }

  ${laptop} {
  }

  ${monitor} {
  }
`;

const DonateButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  padding: 0.5em;
  color: ${white};
  margin: 0.25em;

  text-decoration: none;

  border: 2px solid white;
  border-radius: 10px;

  transform: scale(0.7);

  ${tablet} {
    margin: 0.5em;
    transform: scale(1);
  }

  ${laptop} {
    margin: 1em;
  }

  img {
    width: 50px;
  }

  b {
    font-size: 1.5rem;
    margin: 0 1rem;
  }
`;

export default function Donate() {
  return (
    <Section title={'Support Us'} variant="dark">
      <p>
        Cash donations are easiest and allow us to quickly restock after large events and respond in
        real time to emerging situations.
      </p>
      <p>
        Contributions go toward water, snacks, masks, hand sanitizer, PPE, first aid supplies, and
        other equipment for our protest support team.
      </p>
      <ButtonContainer>
        <DonateButton href="//venmo.com/Detroit313cc" target="_blank">
          <img src="donate/venmo.svg" alt="Venmo Logo" />
          <b>Donate with Venmo</b>
        </DonateButton>
        <DonateButton href="//cash.app/$Detroit313cc" target="_blank">
          <img src="donate/cashapp.svg" alt="CashApp Logo" />
          <b>Donate with Cash App</b>
        </DonateButton>
      </ButtonContainer>
      <p>
        To donate medical or other supplies directly, please{' '}
        <Email href="mailto:313cc@protonmail.com">reach out to us by email</Email>.
      </p>
    </Section>
  );
}
