import React from 'react';
import { init } from 'insights-js';
import Home from './Home';
import BringOnTheBleed from './BringOnTheBleed';

import { Routes, Route } from 'react-router-dom';

function App() {
  init(process.env.REACT_APP_INSIGHTS_ID);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="bringing-on-the-bleed" element={<BringOnTheBleed />} />
      <Route path="botb" element={<BringOnTheBleed />} />
    </Routes>
  );
}

export default App;
