import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Facebook } from '@fortawesome/fontawesome-free/svgs/brands/facebook.svg';
import { ReactComponent as Twitter } from '@fortawesome/fontawesome-free/svgs/brands/twitter.svg';
import { ReactComponent as Mail } from '@fortawesome/fontawesome-free/svgs/regular/envelope.svg';
import { ReactComponent as Instagram } from '@fortawesome/fontawesome-free/svgs/brands/instagram.svg';

import { tablet, laptop, monitor } from '../utils/breakpoints';
import { red } from '../constants/colors';

const Container = styled.section`
  min-height: 40vh;
  background-color: ${red};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const Text = styled.p`
  margin: 0;
  font-family: 'DirtyHeadline';
  text-align: center;
  justify-content: center;
  font-size: 1.5rem;

  transition: font-size 1s ease, padding 1s ease, max-width 1s ease;

  ${tablet} {
    font-size: 2rem;
  }

  ${laptop} {
    font-size: 3rem;
    max-width: 80%;
  }
`;

const Icons = styled.div`
  margin: 2em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 75vw;

  ${tablet} {
    width: 60vw;
  }

  ${laptop} {
    width: 50vw;
  }

  ${monitor} {
    width: 40vw;
  }
`;

const IconLink = styled.a`
  width: 45px;

  ${tablet} {
    width: 60px;
  }

  ${monitor} {
    width: 75px;
  }
`;

const Splash = () => (
  <Container>
    <Text>
      313cc is a street medic and protest support organization based in Detroit, MI providing first
      aid, training, supplies, and logistical support for direct action.
    </Text>
    <Icons>
      <IconLink
        href="//www.facebook.com/313carecollective"
        title="313 Care Collective Facebook Page"
        target="__blank"
      >
        <Facebook />
      </IconLink>
      <IconLink
        href="//www.instagram.com/313carecollective"
        title="313 Care Collective Instagram"
        target="__blank"
      >
        <Instagram />
      </IconLink>
      <IconLink
        href="//twitter.com/313ccDetroit"
        title="313 Care Collective Twitter Page"
        target="__blank"
      >
        <Twitter />
      </IconLink>
      <IconLink
        href="mailto:313cc@protonmail.com"
        title="313 Care Collective Email"
        target="__blank"
      >
        <Mail />
      </IconLink>
    </Icons>
  </Container>
);

export default Splash;
