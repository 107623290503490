import { track as insightsTrack, parameters } from 'insights-js';

export default async function track(id) {
  const { REACT_APP_GEOLOCATION_DB_KEY } = process.env;
  const {
    userAgent,
    deviceMemory,
    hardwareConcurrency,
    languages,
    pdfViewerEnabled,
    platform,
    userAgentData,
  } = window.navigator;

  const payload = {
    id,
    parameters: {
      path: parameters.path(true, true),
      screenType: parameters.screenType(),
      referrer: parameters.referrer(),
      locale: parameters.locale(),
      userAgent,
      deviceMemory,
      hardwareConcurrency,
      languages,
      pdfViewerEnabled,
      platform,
      uaDataBrands: userAgentData?.brands?.map((b) => `${b.brand} v${b.version}`).join(', '),
      uaDataMobile: userAgentData?.mobile,
      uaDataPlatform: userAgentData?.platform,
    },
  };

  const ip = await fetch('//icanhazip.com')
    .then((res) => res.text())
    .then((ip) => ip.replace('\n', ''));

  payload.parameters.ip = ip;
  insightsTrack(payload);

  const geoLocation = await fetch(
    `https://geolocation-db.com/json/${REACT_APP_GEOLOCATION_DB_KEY}`
  ).then((res) => res.json());
  insightsTrack({
    id: 'geolocated',
    parameters: {
      ip,
      countryCode: geoLocation.country_code,
      countryName: geoLocation.country_name,
      city: geoLocation.city,
      postal: geoLocation.postal,
      latitude: geoLocation.latitude,
      longitude: geoLocation.longitude,
      IPv4: geoLocation.IPv4,
      state: geoLocation.state,
    },
  });
}
