import React from 'react';
import styled from 'styled-components';
import { black } from '../constants/colors';
import { laptop, tablet, monitor } from '../utils/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1em;
  width: 100px;

  ${tablet} {
    width: 150px;
  }

  ${laptop} {
    width: 200px;
  }

  ${monitor} {
    width: 325px;
  }
`;

const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${black};
`;

const Image = styled.img`
  width: 75px;

  ${tablet} {
    width: 100px;
  }

  ${laptop} {
    width: 150px;
  }

  ${monitor} {
    width: 200px;
  }

  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 100%;
`;

const Text = styled.b`
  margin-top: 1em;
  height: 2rem;
`;

export default function PartnerLogo({ href, src, name }) {
  return (
    <Container>
      <Link href={href} title={`link to ${name}'s page`} target="_blank">
        <Image src={src} alt={`Logo for ${name}`}></Image>
        <Text>{name}</Text>
      </Link>
    </Container>
  );
}
