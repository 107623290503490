import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import track from '../utils/track';

const Content = styled.section`
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f6e6;
  min-height: 100vh;
`;

const Image = styled.img`
  min-width: 360px;
  max-width: 550px;
  width: 100vw;
`;

const Link = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 18px;
  margin: 0.5em;
  color: #2d5a27;
`;

const Button = styled(Link)`
  background: #2d5a27;
  color: #ffffff;
  box-shadow: #5e5df0 0 10px 20px -10px;
  border-radius: 999px;
  box-sizing: border-box;
  outline: 0 solid transparent;
`;

const Disclaimer = styled.i`
  font-size: 8px;
  margin-top: 4em;
  max-width: 550px;
`;

export default function BringOnTheBleed() {
  track('botb-page');

  return (
    <section>
      <Header />
      <Content>
        <Image
          src="/botb.jpg"
          width="320px"
          alt="Bringing on the Bleed: Botanical Aids for Abortion"
        />
        <Button href="https://youtu.be/6DZCriKpu5w" target="_blank" rel="noreferrer noopener">
          Watch the Presentation
        </Button>
        <Link href="/files/botb-syllabus.pdf">Download The Guide</Link>
        <Disclaimer>
          DISCLAIMER: THIS WEBSITE DOES NOT PROVIDE MEDICAL ADVICE The information, including but
          not limited to, text, graphics, images and other material contained on this website are
          for informational purposes only. No material on this site is intended to be a substitute
          for professional medical advice, diagnosis or treatment. Always seek the advice of your
          physician or other qualified health care provider with any questions you may have
          regarding a medical condition or treatment and before undertaking a new health care
          regimen, and never disregard professional medical advice or delay in seeking it because of
          something you have read on this website.
        </Disclaimer>
      </Content>
    </section>
  );
}
